import React, { useState } from "react";

import SectionContainer from "../SectionContainer";
import classNames from "classnames";

import css from "./SectionClientForm.module.css";

const accordionContent = {
    "open": "Talk to an Expert",
    "close": "Collapse" // keeping this in case we need it again in the future
}

const SectionClientForm = props => {
    const [showAccordion, setShowAccordion] = useState(false);
    return (
        <SectionContainer
            id={props.sectionId}
            className={props.className}
            rootClassName={classNames(props.rootClassName || css.root)}
            appearance={props.appearance}
            options={props.fieldOptions}
        >

            {!showAccordion ? (
            <button
                className={`${css.accordion} ${props.defaultClasses.ctaButton} `}
                onClick={() => setShowAccordion(true)}
            >
                {showAccordion ? accordionContent.close : accordionContent.open}
            </button>) : null}
            
           
            <div className={`${css.panel} ${showAccordion ? css.show : ""}`}>
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSeKd9-VIRHuUNnIYmAHklod1ODU1cj2CcRXQTHowcjGoz42Uw/viewform?embedded=true"
                    
                    width="640"
                    height="1000"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    scrolling="no"
                >
                    Loading…
                </iframe>
            </div>

        </SectionContainer>
    )
}

export default SectionClientForm;